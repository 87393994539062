<template>
  <div :class="{'page': true, 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad', 'is-mobile': deviceType === 'mobile'}">
    <header-index></header-index>
    <div class="people-wp">
      <div class="title-wp">
        <h1 class="text">{{$t('index.ourPeople')}}</h1>
      </div>
      <div class="desc-wp">
        <h5 class="text">{{$t('index.ourPeopleDesc')}}</h5>
      </div>
      <div class="people-items-wp" ref="peopleItems" @mousedown="peopleMouseDown" @mouseup="peopleMouseUp" @mouseleave="peopleMouseLeave" @mousemove="peopleMouseMove">
        <div class="people-item-wp" @click="showPeopleDetail(0)">
          <img src="@/assets/images/people_1.jpg" class="svg-icon" />
            <div class="name-wp">
              <span class="text">Andy Purwohardono</span>
            </div>
            <div class="identity-wp">
              <span class="text">{{$t('index.position1')}}</span>
            </div>
        </div>
        <div class="people-item-wp" @click="showPeopleDetail(1)">
          <img src="@/assets/images/people_2.png" class="svg-icon" />
            <div class="name-wp">
              <span class="text">Profesor Agus Santoso</span>
            </div>
            <div class="identity-wp">
              <span class="text">{{$t('index.position2')}}</span>
            </div>
        </div>
        <div class="people-item-wp the-right" @click="showPeopleDetail(2)">
          <img src="@/assets/images/people_3.png" class="svg-icon" />
            <div class="name-wp">
              <span class="text">Budi Wijaya</span>
            </div>
            <div class="identity-wp">
              <span class="text">{{$t('index.position1')}}</span>
            </div>
        </div>
        <div class="people-item-wp" @click="showPeopleDetail(3)">
          <img src="@/assets/images/people_4.png" class="svg-icon" />
          <div class="name-wp">
              <span class="text">Dimas Pramudito</span>
            </div>
            <div class="identity-wp">
              <span class="text">{{$t('index.position2')}}</span>
            </div>
        </div>
        <div class="people-item-wp last" @click="showPeopleDetail(4)">
          <img src="@/assets/images/people_5.png" class="svg-icon" />
          <div class="name-wp">
              <span class="text">Profesor Adi Sutanto</span>
            </div>
            <div class="identity-wp">
              <span class="text">{{$t('index.position2')}}</span>
            </div>
        </div>
      </div>
    </div>
    <footer-index></footer-index>
    <s3-layer
              :class="{'people-model-wp': true, 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad', 'is-mobile': deviceType === 'mobile'}"
              v-model="peopleModelShow"
              title=""
              area="87.03%"
              :btn="[]"
              :closeBtn="0"
              :shadeClose="true"
              :shade="[0.2, '#000000']"
              :resize="false"
              :isOutAnim="false"
              :scrollbar="false"
              @end="closePeopleModel">
      <div class="header-wp">
        <div class="btn-wp">
          <div class="btn share" @click="toShare">
            <svg-icon icon-class="share" />
          </div>
          <div class="btn close" @click="closePeopleModel">
            <svg-icon icon-class="close" />
          </div>
        </div>
      </div>
      <div class="sep-h"></div>
      <div class="people-info-wp" v-if="currentPeopleIdx !== -1">
        <div class="left-wp">
          <div class="base-wp">
            <div class="avatar-wp">
<!--              <svg-icon :icon-class="peopleList[currentPeopleIdx].avatarCircle" />-->
              <svg-icon icon-class="people_avatar_1" v-if="currentPeopleIdx == 0" />
              <svg-icon icon-class="people_avatar_2" v-if="currentPeopleIdx == 1" />
              <svg-icon icon-class="people_avatar_3" v-if="currentPeopleIdx == 2" />
              <svg-icon icon-class="people_avatar_4" v-if="currentPeopleIdx == 3" />
              <svg-icon icon-class="people_avatar_5" v-if="currentPeopleIdx == 4" />
            </div>
            <div class="name-wp">
              <span class="text">{{str}}{{peopleList[currentPeopleIdx].name}}</span>
            </div>
          </div>
          <div class="desc-wp">
            <span :class="{text: true, main: item.isMain}" v-for="(item, idx) in peopleList[currentPeopleIdx].desc" :key="idx">{{item.text}}</span>
          </div>
        </div>
        <div class="right-wp">
          <div class="btn-wp">
            <div class="btn" @click="prePeople">
              <svg-icon icon-class="arrow_left" />
            </div>
            <div class="btn" @click="nextPeople">
              <svg-icon icon-class="arrow_right" />
            </div>
          </div>
          <div class="base-wp">
            <div class="item-wp">
              <span class="label">{{$t('index.platform')}}:</span>
              <span class="value">{{peopleList[currentPeopleIdx].platform}}</span>
            </div>
            <div class="item-wp">
              <span class="label">{{$t('index.position')}}:</span>
              <span class="value">{{peopleList[currentPeopleIdx].position}}</span>
            </div>
            <div class="item-wp">
              <span class="label">{{$t('index.office')}}:</span>
              <span class="value">{{peopleList[currentPeopleIdx].office}}</span>
            </div>
          </div>
          <div class="sep-h"></div>
        </div>
      </div>
    </s3-layer>
  </div>
</template>

<script>
import HeaderIndex from "@/components/header";
import FooterIndex from "@/components/footer";
// import s3Layer, {layer} from "vue3-layer"
import s3Layer from "vue3-layer"
import {mapGetters} from "vuex";

export default {
  name: 'IndexPage',
  components: {
    HeaderIndex,
    FooterIndex,
    s3Layer,
  },
  props: {},
  data: function () {
    return {
      peopleModelShow: false,
      currentPeopleIdx: -1,
      peopleMouseFlag: false,
      peopleMouseDownX: 0,
      peopleMouseUpX: 0,
      peopleMouseScrollLeft: 0,
      str: ''
    }
  },
  created() {

  },
  mounted() {
  },
  unmounted() {
    this.currentPeopleIdx = -1;
    this.peopleModelShow = true;
  },
  computed: {
    ...mapGetters([
      'deviceType'
    ]),
    peopleList(){
      return [
      {
        avatar: 'people_1.svg',
        avatarCircle: 'people_avatar_1.svg',
        name: 'Andy Purwohardono',
        desc: [
          {
            text: this.$t('index.people1.desc1'),
            isMain: true
          },
          {
            text: this.$t('index.people1.desc2'),
            isMain: false
          },
          {
            text: this.$t('index.people1.desc3'),
            isMain: false
          }
        ],
        platform: this.$t('index.people1.platform'),
        position: this.$t('index.position1'),
        office: this.$t('index.people1.office'),
      },
      {
        avatar: 'people_2.svg',
        avatarCircle: 'people_avatar_2.svg',
        name: 'Profesor Agus Santoso',
        desc: [
          {
            text: this.$t('index.people2.desc1'),
            isMain: true
          },
          {
            text: this.$t('index.people2.desc2'),
            isMain: false
          }
        ],
        platform: this.$t('index.people1.platform'),
        position: this.$t('index.position2'),
        office: this.$t('index.people2.office')
      },
      {
        avatar: 'people_3.svg',
        avatarCircle: 'people_avatar_3.svg',
        name: 'Budi Wijaya',
        desc: [
          {
            text: this.$t('index.people3.desc1'),
            isMain: true
          },
          {
            text: this.$t('index.people3.desc2'),
            isMain: false
          },
          {
            text: this.$t('index.people3.desc3'),
            isMain: false
          }
        ],
        platform: this.$t('index.people1.platform'),
        position: this.$t('index.position1'),
        office: this.$t('index.people3.office')
      },
      {
        avatar: 'people_4.svg',
        avatarCircle: 'people_avatar_4.svg',
        name: 'Dimas Pramudito',
        desc: [
          {
            text: this.$t('index.people4.desc1'),
            isMain: true
          },
          {
            text: this.$t('index.people4.desc2'),
            isMain: false
          },
          {
            text: this.$t('index.people4.desc3'),
            isMain: false
          }
        ],
        platform: this.$t('index.people1.platform'),
        position: this.$t('index.position2'),
        office: this.$t('index.people2.office')
      },
      {
        avatar: 'people_5.svg',
        avatarCircle: 'people_avatar_5.svg',
        name: 'Profesor Adi Sutanto',
        desc: [
          {
            text: this.$t('index.people5.desc1'),
            isMain: true
          },
          {
            text: this.$t('index.people5.desc2'),
            isMain: false
          },
          {
            text: this.$t('index.people5.desc3'),
            isMain: false
          }
        ],
        platform: this.$t('index.people1.platform'),
        position: this.$t('index.position2'),
        office: this.$t('index.people2.office')
      }
      ]
    },
    peopleItems() {
      return this.$refs.peopleItems;
    }
  },
  watch: {
    deviceType: {
      handler(val) {
        this.$nextTick(() => {
          let layerDom = document.getElementsByClassName('people-model-wp')
          if (layerDom.length > 0) {
            layerDom = layerDom[0]
            let className = '';
            if (!window.realMobile) {
              if (val === 'pc') {
                className = 'is-pc';
              } else if (val === 'pad') {
                className = 'is-pad';
              } else if (val === 'mobile') {
                className = 'is-mobile';
              }
            } else {
              className = 'is-mobile'
            }
            layerDom.classList.add(className)
            if (className === 'is-pc') {
              layerDom.classList.remove('is-pad')
              layerDom.classList.remove('is-mobile')
            } else if (className === 'is-pad') {
              layerDom.classList.remove('is-pc')
              layerDom.classList.remove('is-mobile')
            } else if (className === 'is-mobile') {
              layerDom.classList.remove('is-pc')
              layerDom.classList.remove('is-pad')
            }
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    _isMobile() {
      let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return isMobile !== null
    },
    showPeopleDetail(idx) {
      if (this.peopleMouseFlag) {
        return;
      }
      if (this.peopleMouseDownX !== this.peopleMouseUpX) {
        return;
      }
      this.currentPeopleIdx = idx;
      this.peopleModelShow = true;
    },
    closePeopleModel() {
      this.peopleModelShow = false;
      this.currentPeopleIdx = -1;
    },
    prePeople() {
      if (this.currentPeopleIdx === -1) {
        return;
      }
      if (this.currentPeopleIdx === 0) {
        this.currentPeopleIdx = 4;
      } else {
        this.currentPeopleIdx--;
      }
    },
    nextPeople() {
      if (this.currentPeopleIdx === -1) {
        return;
      }
      if (this.currentPeopleIdx === 4) {
        this.currentPeopleIdx = 0;
      } else {
        this.currentPeopleIdx++;
      }
    },
    async toShare() {
      try {
        await navigator.clipboard.writeText(location.href);
        // layer.msg('text copied to clipboard')
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    peopleMouseDown(event) {
      console.log('event', event)
      // if (this.deviceType !== 'pc' && this.deviceType !== 'pad') {
      //   return;
      // }
      // this.peopleMouseFlag = true;
      // this.peopleMouseDownX = event.pageX;
      // this.peopleMouseScrollLeft = this.peopleItems.scrollLeft;
    },
    peopleMouseUp(event) {
      console.log('event', event)
      // if (this.deviceType !== 'pc' && this.deviceType !== 'pad') {
      //   return;
      // }
      // this.peopleMouseFlag = false;
      // this.peopleMouseUpX = event.pageX;
    },
    peopleMouseLeave(event) {
      console.log('event', event)
      // if (this.deviceType !== 'pc' && this.deviceType !== 'pad') {
      //   return;
      // }
      // if (event.pageX < 0 || event.pageX > document.body.offsetWidth) {
      //   this.peopleMouseFlag = false;
      // }
    },
    peopleMouseMove(event) {
      console.log('event', event)
      // if (this.deviceType !== 'pc' && this.deviceType !== 'pad') {
      //   return;
      // }
      // if (this.peopleMouseFlag) {
      //   let moveX = event.clientX;
      //   let scrollX = moveX - this.peopleMouseDownX;
      //   if (scrollX < 0 && this.peopleMouseScrollLeft > 0) {
      //     this.peopleItems.scrollLeft = this.peopleMouseScrollLeft - scrollX;
      //   } else {
      //     this.peopleItems.scrollLeft = this.peopleMouseScrollLeft - scrollX;
      //   }
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .people-wp {
    padding-bottom: 9.125rem;
    overflow: hidden;
    background: linear-gradient(#FFFFFF, #FDFDFD);

    .title-wp {
      margin-top: 10.6875rem;
      text-align: center;
      overflow: hidden;

      .text {
        font-size: 5.625rem;
        font-weight: bold;
        font-family: Source Han Serif SC;
        color: #0A4F88;
        margin: 0;
        padding: 0;
      }
    }

    .desc-wp {
      margin-top: 4rem;
      text-align: center;
      overflow: hidden;

      .text {
        font-size: 1.375rem;
        font-weight: 500;
        font-family: Source Han Serif SC;
        color: #000000;
        margin: 0 auto;
        padding: 0;
        max-width: 45.625%;
        line-height: 1.4;
      }
    }

    .people-items-wp {
      margin-top: 10.25rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 6.625rem;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .people-item-wp {
        //width: 23.6875rem;
        margin-top: 2.5rem;
        width: 32%;
        margin-right: calc(2% - 4px);
        background: #FFFFFF;
        border-radius: 1.5rem;
        padding-bottom: 1.5625rem;
        cursor: pointer;
        border: 1px solid #F9F9F9;
        display: flex;
        flex-direction: column;
        align-items: center;

        .svg-icon {
          width: 100%;
          display: block;
          border-radius: 2.4rem 2.4rem 0 0;
          overflow: hidden;
        }

        .name-wp {
          margin-top: 1.5rem;
          text-align: left;
          margin-left: 0.9375rem;

          .text {
            font-size: 1.625rem;
            font-weight: bold;
            font-family: Source Han Serif SC;
            color: #0A4F88;
            margin: 0;
            padding: 0;
          }
        }

        .identity-wp {
          margin-top: 1rem;
          text-align: left;
          margin-left: 0.9375rem;

          .text {
            font-size: 1rem;
            font-weight: 500;
            font-family: PingFang SC;
            color: #000000;
            margin: 0;
            padding: 0;
          }
        }
      }

      .the-right {
        margin-right: 0;
      }

      .last {
        margin-right: 0;
      }
    }

    .people-items-wp::-webkit-scrollbar {
      display: none;
    }
  }
}

.is-pad {
  $mul: 1;

  .people-wp {
    .title-wp {
      .text {
        font-size: 5.625rem * $mul;
      }
    }

    .desc-wp {
      .text {
        font-size: 1.375rem * $mul;
      }
    }

    .people-items-wp {
      .people-item-wp {
        .name-wp {
          .text {
            font-size: 1.25rem * $mul;
          }
        }

        .identity-wp {
          .text {
            font-size: 1rem * $mul;
          }
        }
      }
    }
  }
}

.is-mobile {
  $mul: 1;

  .people-wp {
    .title-wp {
      .text {
        font-size: 5.625rem * $mul;
      }
    }

    .desc-wp {
      .text {
        font-size: 1.5rem * $mul;
        max-width: 90%;
      }
    }

    .people-items-wp {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 5%;

      .people-item-wp {
        width: 48% !important;
        margin-bottom: 2.5rem;
        justify-content: center;
        margin-right: 0;

        .svg-icon {
          width: 100%;
          height: 100%;
        }

        .name-wp {
          width: 100%;
          text-align: center;
          .text {
            font-size: 1.75rem * $mul;
          }
        }

        .identity-wp {
          width: 100%;
          text-align: center;

          .text {
            font-size: 1.375rem * $mul;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.people-model-wp {
  border-radius: 1.75rem !important;
  background: #FFFFFF !important;
  box-shadow: none !important;
  max-height: 80vh !important;
  min-height: 31.25rem !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;

  .layui-layer-content {
    padding: 0 !important;
    margin: 0 !important;

    overflow: scroll !important;
    height: 100%;
  }
  .layui-layer-content::-webkit-scrollbar {
    display: none;
  }
  .layui-layer-btn {
    display: none;
  }

  .header-wp {
    padding: 2.375rem 0 2.6875rem 0;

    .btn-wp {
      margin-left: auto;
      display: flex;
      margin-right: 4.9375rem;
      width: fit-content;

      .btn {
        width: 5.0625rem;
        height: 3.375rem;
        border-radius: 1.625rem;
        border: 1px solid #B4C8D7;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
      }

      .share {
        .svg-icon {
          width: 1rem;
          height: 1.0625rem;
          display: block;
        }
      }

      .close {
        margin-left: 1.75rem;

        .svg-icon {
          width: 1rem;
          height: 1rem;
          display: block;
        }
      }
    }
  }

  .sep-h {
    border-top: 1px solid #E0E0E0;
  }

  .people-info-wp {
    display: flex;

    .left-wp {
      padding: 4rem 2.4375rem 2.625rem 5.375rem;
      width: 68.94%;
      flex-shrink: 0;
      box-sizing: border-box;

      .base-wp {
        display: flex;
        align-items: center;

        .avatar-wp {
          flex-shrink: 0;

          .svg-icon {
            width: 14.625rem;
            height: 14.625rem;
            display: block;
          }
        }

        .name-wp {
          flex-shrink: 0;
          margin-left: 2.75rem;

          .text {
            color: #000000;
            font-weight: bold;
            font-size: 2.5rem;
            font-family: Source Han Serif SC;
          }
        }
      }

      .desc-wp {
        margin-top: 1.3125rem;

        .text {
          color: #000000;
          font-weight: 300;
          font-size: 1.5rem;
          font-family: Brown;
          display: block;
          line-height: 1.4;
          margin-bottom: 1.25rem;
        }

        .main {
          font-weight: bold;
        }
      }
    }

    .right-wp {
      flex-grow: 1;
      background: #F3F3F3;

      .btn-wp {
        margin-top: 7rem;
        margin-left: 3.25rem;
        display: flex;
        align-items: center;

        .btn {
          padding: 1.125rem 1.8125rem;
          background: #FFFFFF;
          border-radius: 1.625rem;
          margin-right: 1.75rem;
          cursor: pointer;

          .svg-icon {
            width: 1.5rem;
            height: 1.125rem;
            display: block;
          }
        }
      }

      .base-wp {
        margin-top: 2.875rem;
        padding-left: 3.25rem;
        box-sizing: border-box;

        .item-wp {
          margin-top: 0.875rem;
          display: flex;
          align-items: center;

          .label {
            color: #000000;
            font-weight: 400;
            font-size: 1.5rem;
            font-family: Brown;
            display: block;
          }

          .value {
            color: #0A4F88;
            font-weight: 600;
            font-size: 1.5rem;
            font-family: Brown;
            display: block;
            margin-left: 0.625rem;
          }
        }
      }

      .sep-h {
        margin-top: 4.875rem;
        border-top: 1px solid #E0E0E0;
      }
    }
  }
}

.people-model-wp::-webkit-scrollbar {
  display: none;
}

.people-model-wp.is-pad {
  $mul: 1;

  .people-info-wp {
    .left-wp {
      .base-wp {
        .name-wp {
          .text {
            font-size: 2.5rem * $mul;
          }
        }
      }

      .desc-wp {
        .text {
          font-size: 1.5rem * $mul;
        }
      }
    }

    .right-wp {
      .base-wp {
        .item-wp {
          flex-direction: column;
          align-items: flex-start;

          .label {
            font-size: 1.5rem * $mul;
            line-height: 1.1;
          }

          .value {
            font-size: 1.5rem * $mul;
            margin-left: 0;
            line-height: 1.1;
          }
        }
      }
    }
  }
}

.people-model-wp.is-mobile {
  $mul: 1;

  .people-info-wp {
    flex-direction: column;

    .left-wp {
      width: 100%;
      padding-left: 10%;
      padding-right: 10%;

      .base-wp {
        flex-direction: column;

        .name-wp {
          margin-top: 0.625rem;
          margin-left: 0;

          .text {
            font-size: 2.5rem * $mul;
          }
        }
      }

      .desc-wp {
        .text {
          font-size: 1.5rem * $mul;
        }
      }
    }

    .right-wp {
      display: flex;
      flex-direction: column-reverse;
      padding-left: 10%;
      padding-right: 10%;

      .base-wp {
        margin-top: 2rem;
        margin-left: 0;
        padding-left: 0;

        .item-wp {
          flex-direction: column;
          align-items: flex-start;

          .label {
            font-size: 1.5rem * $mul;
            line-height: 1.1;
          }

          .value {
            font-size: 1.5rem * $mul;
            margin-left: 0;
            line-height: 1.1;
          }
        }
      }

      .sep-h {
        display: none;
      }

      .btn-wp {
        margin-top: 2rem;
        margin-bottom: 3rem;
        margin-left: 0;
      }
    }
  }
}
</style>
